// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-flexible-content-page-jsx": () => import("/opt/build/repo/src/templates/FlexibleContentPage.jsx" /* webpackChunkName: "component---src-templates-flexible-content-page-jsx" */),
  "component---src-templates-blog-jsx": () => import("/opt/build/repo/src/templates/Blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-legal-jsx": () => import("/opt/build/repo/src/templates/Legal.jsx" /* webpackChunkName: "component---src-templates-legal-jsx" */),
  "component---src-templates-promotions-jsx": () => import("/opt/build/repo/src/templates/Promotions.jsx" /* webpackChunkName: "component---src-templates-promotions-jsx" */),
  "component---src-templates-activity-type-jsx": () => import("/opt/build/repo/src/templates/ActivityType.jsx" /* webpackChunkName: "component---src-templates-activity-type-jsx" */),
  "component---src-templates-restaurant-jsx": () => import("/opt/build/repo/src/templates/Restaurant.jsx" /* webpackChunkName: "component---src-templates-restaurant-jsx" */),
  "component---src-templates-contact-jsx": () => import("/opt/build/repo/src/templates/Contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-home-jsx": () => import("/opt/build/repo/src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-all-restaurants-jsx": () => import("/opt/build/repo/src/templates/AllRestaurants.jsx" /* webpackChunkName: "component---src-templates-all-restaurants-jsx" */),
  "component---src-templates-spa-jsx": () => import("/opt/build/repo/src/templates/Spa.jsx" /* webpackChunkName: "component---src-templates-spa-jsx" */),
  "component---src-templates-room-jsx": () => import("/opt/build/repo/src/templates/Room.jsx" /* webpackChunkName: "component---src-templates-room-jsx" */),
  "component---src-templates-all-rooms-jsx": () => import("/opt/build/repo/src/templates/AllRooms.jsx" /* webpackChunkName: "component---src-templates-all-rooms-jsx" */),
  "component---src-templates-post-jsx": () => import("/opt/build/repo/src/templates/Post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-blog-page-jsx": () => import("/opt/build/repo/src/templates/BlogPage.jsx" /* webpackChunkName: "component---src-templates-blog-page-jsx" */),
  "component---src-templates-blog-category-jsx": () => import("/opt/build/repo/src/templates/BlogCategory.jsx" /* webpackChunkName: "component---src-templates-blog-category-jsx" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

