import { createStore as reduxCreateStore } from 'redux'

const reducer = (state, action) => {
  switch (action.type) {
    case `SHOW_DESKTOP_HEADER_BORDER`:
      return Object.assign({}, state, {
        showBorder: action.showBorder
      })
    case `LANG_DATA`:
      return Object.assign({}, state, {
        langData: action.langData
      })
    case `SCROLL_TO`:
      return Object.assign({}, state, {
        scrollTarget: action.scrollTarget
      })
    default:
      return state
  }
}

const initialState = {
  showBorder: false,
  langData: {
    locale: '',
    translations: [
      {
        path: '',
        native_name: ''
      }
    ]
  },
  scrollTarget: null
}

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore
